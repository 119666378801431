import {Link} from "react-router-dom";
import React from "react";

export default function LineaLogoBox() {
  return (
    <div className='p-2'>
      <div
        className='d-flex align-items-center justify-content-center'
        style={{
          borderRadius: '6px',
          background: 'linear-gradient(1.07deg, rgba(14,62,61,0.89) 27.81%, rgba(14,62,61,0.73) 98.95%)',
          backdropFilter: 'blur(4px)',
        }}
      >
        <div className="d-none d-sm-block">
          <img className='img-fluid p-3' alt="Línea de San Juan Logo" src="/LaLineaSJ-Logo-cropped.svg" style={{width: 200}}/>
        </div>
        <div className="d-xs-block d-sm-none">
          <img className='img-fluid p-3' alt="Línea de San Juan Logo" src="/LaLineaSJ-Logo-cropped.svg" style={{width: 100}}/>
        </div>
      </div>
    </div>
  );
}