export default function FormControl({
                              type = 'text',
                              id = '',
                              name = '',
                              label = '',
                              required = false,
                              className = '',
                              ...props
                            }) {
  const failsafeName = name || id;
  const failsafeId = id || name;

  const inputProps = {
    id: failsafeId,
    name: failsafeName,
    type: type,
    required: required,
    className: 'form-control ' + className,
  };

  return (
    <div className='form-group mb-3'>
      <label htmlFor={failsafeId}>
        {label} {required ? <span className='text-danger'> *</span> : null}
      </label>
      {type === 'textarea' ? (
        <textarea {...inputProps} {...props} />
      ) : (
        <input {...inputProps} {...props} />
      )}
      <div className='invalid-feedback'>&nbsp;</div>
    </div>
  );
}
