import {FeatureCollection} from "geojson";

export type Page<T> = {
  page_size: number;
  page_number: number;
  total_records: number;
  records: T[];
}

export const emptyPage = {
  page_number: 0, page_size: 0, records: [], total_records: 0
} as Page<any>;

export const emptyFeatureCollection = {type: 'FeatureCollection', features: []} as FeatureCollection;

export type AuditFieldsType = {
  created_by: string;
  created_at: string;
  updated_by: string;
  updated_at: string;
}

export type Dimensions = {
  height: number;
  width: number;
}

export type EtaView = {
  subroute_id: number;
  subroute_name: string;
  subroute_direction: string;
  route_id: number;
  route_color: string;
  route_code: string;
  route_name: string;
  route_mode: string;
  route_network: string;
  route_operator: string;
  vehicle_id: number;
  vehicle_subroute_progress_meters: number;
  device_ts_utc: string;
  position_lat: number;
  position_lon: number;
  user_subroute_position: number;
  subroute_length_meters: number;
  distance: number;
  avg_speed: number;
  eta: number;
  eta_type: string;
  scheduled_stop_time: string;
  headway_min: number;
  following_eta: number;
  trip_departure_time: string;
  stop_lat: number;
  stop_lon: number;
  stop_distance: number;
  stop_subroute_m: number;
  stop_name: string;
  stop_code: string;
  scheduled_etas: Object[];
  vehicle_etas: Object[];
}

export const inspectionResolutions = new Map([
  ['1', 'OK'],
  ['2', 'Falta fluidos'],
  ['3', 'Desgastado'],
  ['4', 'No funciona'],
  ['5', 'Dañado o roto'],
  ['6', 'Perdido / no existente'],
  ['7', 'No aplica'],
]);

export const inspectionTargets = new Map([
  ['motor_oil', 'Aceite motor'],
  ['transmision_fuild', 'Aceite transmisión'],
  ['coolant', 'Coolant'],
  ['power_steering_fluid', 'Power steering'],
  ['belts', 'Correas'],
  ['hoses', 'Mangas'],
  ['urea', 'Urea'],
  ['batteries', 'Baterías'],
  ['cables', 'Cablería'],
  ['tires', 'Gomas'],
  ['studs', 'Espárragos'],
  ['bumpers', 'Bumpers'],
  ['external_conditions', 'Condiciones externas'],
  ['disabled_ramp', 'Rampa de impedidos'],
  ['lamps', 'Luces'],
  ['wipers', 'Wipers'],
  ['winshield_windows', 'Cristales'],
  ['rearview_mirrors', 'Retrovisores'],
  ['gauges', 'Relojes indicadores'],
  ['horn', 'Bocina'],
  ['brakes', 'Frenos'],
  ['suspension', 'Suspensión'],
  ['air_conditioning', 'Aire acondicionado'],
  ['security_equipment', 'Equipo de seguridad (extintor, triángulos, primeros auxilios)'],
  ['seat_belts', 'Cinturones de seguridad'],
  ['radio', 'Radio'],
  ['monitors', 'Monitores'],
  ['speaker', 'Altavoz'],
  ['internal_conditions', 'Condiciones interiores'],
]);