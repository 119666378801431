import {formatTimestamp} from '../utils/format';
import DisplayField from './DisplayField';

export default function AuditFields({record}) {
  return (
    <>
      <div className='row mt-3'>
        <DisplayField label="Creado por" value={record.created_by}/>
        <DisplayField label="Creado en" value={formatTimestamp(record.created_at)}/>
      </div>

      <div className='row mt-3'>
        <DisplayField label="Actualizado por" value={record.updated_by}/>
        <DisplayField label="Actualizado en" value={formatTimestamp(record.updated_at)}/>
      </div>
    </>
  )
}