import React from 'react';
import {Container, Dropdown, Navbar, Offcanvas} from 'react-bootstrap';
import {FaUser} from 'react-icons/fa';
import IfAuthorized from './IfAuthorized';
import {Link, useNavigate} from "react-router-dom";
import MenuNavLink from "./MenuNavLink";


export function OffCanvas({
                            user = {username: 'usuario', display_name: 'Usuario', authorities: []},
                            onLogout = () => {
                            },
                          }) {
  return (
    <Navbar expand={false} className='d-lg-none'>
      <Container fluid>
        <Navbar.Toggle aria-controls='basic-navbar-nav'/>
        <Navbar.Offcanvas id='basic-navbar-nav' className='bg-light' style={{width: 280}}>
          <Offcanvas.Header>
            <Logo/>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <MenuNav onLogout={onLogout} user={user}/>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export function Sidebar({
                          user = {username: 'usuario', display_name: 'Usuario', authorities: []},
                          onLogout = () => {
                          },
                        }) {


  return (
    <div className='d-none d-lg-flex flex-column flex-shrink-0 p-3 bg-light' style={{width: '280px'}}>
      {/*<pre>{JSON.stringify(user, null, 2)}</pre>*/}
      <Logo/>
      <hr/>
      <MenuNav onLogout={onLogout} user={user}/>
    </div>
  );
}

export default {Sidebar, OffCanvas};

function Logo() {
  return (
    <Link
      to='/'
      className='d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none w-100'
      style={{
        borderRadius: '6px',
        background: 'linear-gradient(1.07deg, rgba(14,62,61,0.89) 27.81%, rgba(14,62,61,0.73) 98.95%)',
        backdropFilter: 'blur(4px)',
      }}
    >
      <img className='img-fluid pt-3 pb-3 p-2' alt="Logo línea" src="/LaLineaSJ-Logo-cropped.svg" style={{width: 200}}/>
    </Link>
  );
}

function MenuNav({
                   user = {username: 'usuario', display_name: 'Usuario', authorities: []},
                   onLogout = () => {
                   },
                 }) {

  const navigate = useNavigate();
  return (
    <>
      <ul className='nav nav-pills flex-column mb-auto'>
        <IfAuthorized user={user} match={/ADMIN/}>
          <MenuNavLink name='Mapa administrativo' to='/adminMap'/>
        </IfAuthorized>
        <MenuNavLink name='Mapa público' to='/publicMap'/>
        <IfAuthorized user={user} match={/ADMIN|DRIVER/}>
          <MenuNavLink name='Informes de operación' to='/opsLog'/>
        </IfAuthorized>
        <IfAuthorized user={user} match={/ADMIN/}>
          <MenuNavLink name='Estadísticas' to='/stats/ridership'/>
        </IfAuthorized>
        <MenuNavLink name='Rutas' to='/route'/>
        <IfAuthorized user={user} match={/ADMIN/}>
          <MenuNavLink name='Usuarios' to='/userAdmin'/>
        </IfAuthorized>
        <IfAuthorized user={user} match={/ADMIN/}>
          <MenuNavLink name='Vehículos' to='/vehicle'/>
        </IfAuthorized>
        <IfAuthorized user={user} match={/ADMIN/}>
          <MenuNavLink name='Modelos de vehículos' to='/vehicleModel'/>
        </IfAuthorized>
        <IfAuthorized user={user} match={/ADMIN/}>
          <MenuNavLink name='Choferes' to='/driver'/>
        </IfAuthorized>
        <IfAuthorized user={user} match={/ADMIN/}>
          <MenuNavLink name='Avisos' to='/alert'/>
        </IfAuthorized>
        <MenuNavLink name='Estimados de llegada' to='/arrivalTimeMeta'/>
        {/*<IfAuthorized user={user} match={/ADMIN/}>
          <MenuNavLink name='Cargar Configuración OSM' to='/osmLoader'/>
        </IfAuthorized>*/}
      </ul>

      <hr/>

      <Dropdown>
        <Dropdown.Toggle as='div'>
          <div className='rounded-circle me-2' style={{width: 32, height: 32, display: 'inline-block'}}>
            <FaUser/>
          </div>
          <strong>{user?.display_name || user?.username}</strong>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => navigate('/userProfile')}>Perfil de usuario</Dropdown.Item>
          <Dropdown.Item onClick={onLogout}>Salir</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

