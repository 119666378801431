import ValidatedForm from '../components/ValidatedForm';
import FormControl from '../components/FormControl';
import React, {useEffect, useState} from 'react';
import {onChange} from '../utils/utils';
import {FaKey, FaSpinner} from 'react-icons/fa';
import {useLazyPostAuthQuery} from "../redux/services/auth";
import {Link, useNavigate} from "react-router-dom";
import {setToken} from "../redux/authSlice";
import {useDispatch} from "react-redux";

export default function LoginView() {

  const [postAuth, {data, isSuccess, error, isError, isFetching}] = useLazyPostAuthQuery();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authenticationError, setAuthenticationError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setAuthenticationError(isError ? error?.data?.errorCode : null);
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setToken(data.jwttoken));
      navigate('/');
    }
  }, [isSuccess]);


  function handleSubmit() {
    setAuthenticationError(null);
    postAuth({username, password});
  }

  return (
    <>

      <main className='container'>

        <div className="d-flex align-items-center mt-4 p-4 justify-content-between gap-5" style={{
          borderRadius: '6px',
          background: 'linear-gradient(1.07deg, rgba(14,62,61,0.89) 27.81%, rgba(14,62,61,0.73) 98.95%)',
          backdropFilter: 'blur(4px)',
        }}>
          <img className='img-fluid' alt="Logo línea" src="/LaLineaSJ-Logo-cropped.svg" style={{width: 250}}/>
          <div>
            <img className='img-fluid p-2' alt="MSJ Logo" src="/msj-escudo.svg"/>
            <img className='img-fluid p-2' alt="Texto Logo" src="/logo_txt.svg"/>
          </div>
        </div>

        <hr/>
        <h2>Open Data Dashboard de transporte público</h2>
        <h3>Municipio Autónomo de San Juan</h3>


        <div className="row">
          <div className="col-md">
            <div className='p-2'>
                <GradientBtnLink text="Mapa de rutas" to="/map"/>
            </div>
            <div className='p-2'>
              <GradientBtnLink text="Mapa de tiempo de espera" to="/arrivalTimeMap"/>
            </div>
          </div>
          <div className="col-md">
            <div className="card">
              <div className="card-body">
                <div className="card-title"><h4>Acceso administrativo</h4></div>
                {authenticationError && (
                  <div className="alert alert-danger">{authenticationError}</div>
                )}

                <ValidatedForm onValidSubmit={handleSubmit}>
                  <FormControl
                    id='username'
                    label='Usuario'
                    value={username}
                    required
                    onChange={onChange(setUsername)}
                  />
                  <FormControl
                    type={showPassword ? 'input' : 'password'}
                    id='password'
                    required
                    label='Contraseña'
                    value={password}
                    onChange={onChange(setPassword)}
                  />
                  <div className='row'>
                    <div className='col'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input' type='checkbox' id='showPassword'
                          checked={showPassword}
                          onChange={() => setShowPassword(!showPassword)}
                        />
                        <label className='form-check-label' htmlFor='showPassword'>
                          Mostrar contraseña
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-4 offset-4 text-center'>
                      <div className="d-grid ">
                        <button type='submit' className='btn btn-block btn-lg btn-primary'>{isFetching && <>
                          <FaSpinner/>&nbsp;</>}<FaKey/> Ingresar
                        </button>
                      </div>
                    </div>
                  </div>
                </ValidatedForm>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

function GradientBtnLink({text, to}) {
  return (
    <Link to={to} style={{
      textDecoration: 'none',
    }}>
      <h3
        className='d-flex align-items-center justify-content-center p-2 text-light'
        style={{
          textDecoration: 'none',
          borderRadius: '6px',
          background: 'linear-gradient(1.07deg, rgba(14,62,61,0.89) 27.81%, rgba(14,62,61,0.73) 98.95%)',
          backdropFilter: 'blur(4px)',
        }}
      >
        {text}
      </h3>
    </Link>
  )
}
