import {useNavigate, useParams} from "react-router-dom";
import TitleRow from "../../components/TitleRow";
import DisplayField from "../../components/DisplayField";
import React, {useEffect} from "react";
import {FaArrowLeft, FaPencilAlt, FaTrash} from "react-icons/fa";
import IfAuthorized from "../../components/IfAuthorized";
import {useSelector} from "react-redux";
import {useDeleteAlertMutation, useFindAlertQuery} from "../../redux/services/alert";
import {Col} from "../../components/Primitives";
import {BsCheckSquare, BsSquare} from "react-icons/bs";
import AuditFields from "../../components/AuditFields";
import {formatTimestamp} from "../../utils/format";
import {useDeleteObservationMutation} from "../../redux/services/observation";
import {toast} from "react-toastify";

export default function AlertView() {
  const {id} = useParams();
  const navigate = useNavigate();
  const {data: record = {}, isSuccess, isError, error} = useFindAlertQuery(id);
  const [deleteAlert, {isSuccess: deleteAlertSuccess}] = useDeleteAlertMutation();
  const {user} = useSelector(state => state.user);

  useEffect(() => {
    if (deleteAlertSuccess) {
      navigate(`/alert`);
      toast.success('El aviso fue borrado exitosamente.');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [deleteAlertSuccess]);

  function handleDeletePressed() {
    if (!window.confirm('¿Estás seguro de que deseas borrar el aviso permanentemente?')) {
      return;
    }
    deleteAlert(id);
  }

  return (
    <main className='container'>
      <TitleRow
        title={`${record.header}`}
        buttons={
          <>
            <button
              type='button' className='btn btn-outline-primary'
              onClick={() => navigate(`/alert`)}
            ><FaArrowLeft/> Regresar
            </button>
            <IfAuthorized user={user} match={/ADMIN/}>
              <button
                type='button' className='btn btn-primary'
                onClick={handleDeletePressed}
              ><FaTrash/> Borrar
              </button>
            </IfAuthorized>
            <IfAuthorized user={user} match={/ADMIN/}>
              <button
                type='button' className='btn btn-primary'
                onClick={() => navigate(`/alert/${id}/edit`)}
              ><FaPencilAlt/> Modificar
              </button>
            </IfAuthorized>
          </>
        }
      />

      {/*<pre className='small pre-scrollable'>{JSON.stringify({record}, null, 2)}</pre>*/}

      <div className='row mt-3'>
        <DisplayField label='Fecha y hora desde' value={formatTimestamp(record.start_ts)}/>
        <DisplayField label='Fecha y hora hasta' value={formatTimestamp(record.end_ts)}/>
      </div>
      <div className='row mt-3'>
        <div className='col-3'>Contenido</div>
        <div className='col fw-bold'>{record.description}</div>
      </div>

      <AuditFields record={record}/>


    </main>
  );
}