import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Menu from "../components/Menu";

// const LoginView = lazy(() => import("../views/Login"));
// const SingleRouteMap = lazy(() => import("../views/map/SingleRouteMap"));
// const PublicMap = lazy(() => import("../views/map/PublicMap"));

import LoginView from "../views/Login";
import SingleRouteMap from "../views/map/SingleRouteMap";
import PublicMap from "../views/map/PublicMap";
import SidebarLayoutView from "../views/SidebarLayoutView";
import {Alert} from "react-bootstrap";
import AlertEdit from "../views/alert/AlertEdit";
import AlertList from "../views/alert/AlertList";
import AlertView from "../views/alert/AlertView";
import AlertNew from "../views/alert/AlertNew";

const AdminMap = lazy(() => import("../views/map/AdminMap"));
const PublicMapMetaView = lazy(() => import("../views/map/PublicMapMetaView"));
const ArrivalTimeMap = lazy(() => import("../views/arrivalTime/ArrivalTimeMap"));
const ArrivalTimeMapMetaView = lazy(() => import("../views/arrivalTime/ArrivalTimeMapMetaView"));
const UserAdminList = lazy(() => import("../views/userAdmin/UserAdminList"));
const UserAdminView = lazy(() => import("../views/userAdmin/UserAdminView"));
const UserAdminEdit = lazy(() => import("../views/userAdmin/UserAdminEdit"));
const UserAdminPassword = lazy(() => import("../views/userAdmin/UserAdminPassword"));
const UserAdminNew = lazy(() => import("../views/userAdmin/UserAdminNew"));
const OpsLogChecklistEdit = lazy(() => import("../views/opsLog/OpsLogChecklistEdit"));
const OpsLogEdit = lazy(() => import("../views/opsLog/OpsLogEdit"));
const OpsLogNew = lazy(() => import("../views/opsLog/OpsLogNew"));
const OpsLogView = lazy(() => import("../views/opsLog/OpsLogView"));
const OpsLogList = lazy(() => import("../views/opsLog/OpsLogList"));
const PassengerCountEdit = lazy(() => import("../views/passengerCount/PassengerCountEdit"));
const PassengerCountNew = lazy(() => import("../views/passengerCount/PassengerCountNew"));
const ObservationNew = lazy(() => import("../views/observation/ObservationNew"));
const RidershipStatsView = lazy(() => import("../views/stats/RidershipStatsView"));
const RouteAdminList = lazy(() => import("../views/routeAdmin/RouteAdminList"));
const RouteAdminEdit = lazy(() => import("../views/routeAdmin/RouteAdminEdit"));
const RouteAdminView = lazy(() => import("../views/routeAdmin/RouteAdminView"));
const UserProfileView = lazy(() => import("../views/userProfile/UserProfileView"));
const VehicleModelList = lazy(() => import("../views/vehicleModel/VehicleModelList"));
const VehicleModelNew = lazy(() => import("../views/vehicleModel/VehicleModelNew"));
const VehicleModelEdit = lazy(() => import("../views/vehicleModel/VehicleModelEdit"));
const VehicleModelView = lazy(() => import("../views/vehicleModel/VehicleModelView"));
const VehicleModelDiagram = lazy(() => import("../views/vehicleModel/VehicleModelDiagram"));
const VehicleList = lazy(() => import("../views/vehicle/VehicleList"));
const VehicleNew = lazy(() => import("../views/vehicle/VehicleNew"));
const VehicleEdit = lazy(() => import("../views/vehicle/VehicleEdit"));
const VehicleView = lazy(() => import("../views/vehicle/VehicleView"));
const DriverList = lazy(() => import("../views/driver/DriverList"));
const DriverNew = lazy(() => import("../views/driver/DriverNew"));
const DriverEdit = lazy(() => import("../views/driver/DriverEdit"));
const DriverView = lazy(() => import("../views/driver/DriverView"));
const OsmLoaderView = lazy(() => import("../views/osmLoader/OsmLoaderView"));
const LandingView = lazy(() => import("../views/LandingView"));

export default function LazyRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={(
        <div>
          <div className='d-lg-block'>
            <div className='d-none d-lg-flex flex-column flex-shrink-0 p-3 bg-light' style={{width: '280px'}}>
              <ul className='nav nav-pills flex-column mb-auto'>
                <li>Cargando...</li>
              </ul>
            </div>
          </div>
          Cargando...
        </div>
      )}
      >
        <Routes>
          <Route path='/login' element={<LoginView/>}/>
          <Route path='/arrivalTimeMap' element={<ArrivalTimeMap/>}/>
          <Route path="/map/:routeCode" element={<SingleRouteMap/>}/>
          <Route path="/map" element={<PublicMap/>}/>
          <Route path='/' element={<SidebarLayoutView/>}>
            <Route path='userAdmin/:id/edit' element={<UserAdminEdit/>}/>
            <Route path='userAdmin/:id/password' element={<UserAdminPassword/>}/>
            <Route path='userAdmin/new' element={<UserAdminNew/>}/>
            <Route path='userAdmin/:id' element={<UserAdminView/>}/>
            <Route path='userAdmin' element={<UserAdminList/>}/>
            <Route path='opsLog/:id/checklist' element={<OpsLogChecklistEdit/>}/>
            <Route path='opsLog/:id/edit' element={<OpsLogEdit/>}/>
            <Route path='opsLog/new' element={<OpsLogNew/>}/>
            <Route path='opsLog/:id' element={<OpsLogView/>}/>
            <Route path='opsLog' element={<OpsLogList/>}/>
            <Route path='passengerCount/:id/edit' element={<PassengerCountEdit/>}/>
            <Route path='passengerCount/new' element={<PassengerCountNew/>}/>
            <Route path='observation/new' element={<ObservationNew/>}/>
            <Route path='stats/ridership' element={<RidershipStatsView/>}/>
            <Route path='route/:id/edit' element={<RouteAdminEdit/>}/>
            <Route path='route/:id' element={<RouteAdminView/>}/>
            <Route path='route' element={<RouteAdminList/>}/>
            <Route path='vehicleModel/:id/edit' element={<VehicleModelEdit/>}/>
            <Route path='vehicleModel/:id/diagram' element={<VehicleModelDiagram/>}/>
            <Route path='vehicleModel/new' element={<VehicleModelNew/>}/>
            <Route path='vehicleModel/:id' element={<VehicleModelView/>}/>
            <Route path='vehicleModel' element={<VehicleModelList/>}/>
            <Route path='vehicle/:id/edit' element={<VehicleEdit/>}/>
            <Route path='vehicle/new' element={<VehicleNew/>}/>
            <Route path='vehicle/:id' element={<VehicleView/>}/>
            <Route path='vehicle' element={<VehicleList/>}/>
            <Route path='driver/:id/edit' element={<DriverEdit/>}/>
            <Route path='driver/new' element={<DriverNew/>}/>
            <Route path='driver/:id' element={<DriverView/>}/>
            <Route path='driver' element={<DriverList/>}/>
            <Route path='alert/:id/edit' element={<AlertEdit/>}/>
            <Route path='alert/new' element={<AlertNew/>}/>
            <Route path='alert/:id' element={<AlertView/>}/>
            <Route path='alert' element={<AlertList/>}/>
            <Route path='osmLoader' element={<OsmLoaderView/>}/>
            <Route path='userProfile' element={<UserProfileView/>}/>
            <Route path='userProfile/edit' element={<h2>En construcción</h2>}/>
            <Route path='adminMap' element={<AdminMap/>}/>
            <Route path='publicMap' element={<PublicMapMetaView/>}/>
            <Route path='arrivalTimeMeta' element={<ArrivalTimeMapMetaView/>}/>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}