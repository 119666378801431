import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {setToken} from "../redux/authSlice";
import {useDispatch, useSelector} from "react-redux";
import jwt_decode from 'jwt-decode';


export function useInput(initialValue = '', emptyToNull = false) {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(initialValue),
    emptyToNull,
    initialValue,
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
    },
  };
}

export function useLogout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return function () {
    dispatch(setToken(null));
    navigate('/login');
  }
}

export function useCheckToken(onValidTokenFound = (token) => {}) {
  const { token } = useSelector(state => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if(!token || token === 'null') {
      navigate('/login');
      return;
    }
    const {exp} = jwt_decode(token);
    if(exp < (new Date().getTime() / 1000)) {
      //expired
      navigate('/login');
      return;
    }

    onValidTokenFound(token)

  }, [token])
}

export function usePaging() {
  const [page_number, setPage_number] = useState(1);
  const [page_size, setPage_size] = useState(0);
  const [total_records, setTotal_records] = useState(0);
  const [records, setRecords] = useState([]);

  return {
    page_number, setPage_number,
    page_size, setPage_size,
    total_records, setTotal_records,
    records, setRecords,
    pageRecordOffset: (page_number - 1) * page_size,
    setData: ({ total_records = 0, page_size = 0, records = [] }) => {
      setTotal_records(total_records);
      setPage_size(page_size);
      setRecords(records);
    },
  };
}


/*
export function usePermalink() {
  const router = useRouter();
  const [isReady, setReady] = useState(false);
  const [permalinkData, setPermalinkData] = useState({});

  useEffect(() => {
    setPermalinkData(router.query);
    setReady(true);
  }, [router.isReady]);

  function updatePermalink(nextPermalinkData) {
    setPermalinkData(nextPermalinkData);
    router.replace(`${router.pathname}?${stringifyPermalinkData(nextPermalinkData)}`, undefined, { shallow: true }).then();
  }

  function stringifyPermalinkData(permalinkData) {
    const query = new URLSearchParams();
    for (const [key, val] of Object.entries(permalinkData)) {
      if (!val) {
        continue;
      }

      query.append(key, String(val));
    }
    return query.toString();
  }

  return {
    isReady,
    permalinkData,
    updatePermalink,
  };

}*/
