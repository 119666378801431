import {format, parseISO, formatISO, parseJSON} from 'date-fns';
import es from 'date-fns/locale/es';
import formatRFC3339 from 'date-fns/formatRFC3339'
import intlFormatDistance from 'date-fns/intlFormatDistance'
import formatDuration from 'date-fns/formatDuration'


export function formatTimestamp(date: string): string {
  if (!date) {
    return '';
  }

  return format(parseISO(date), 'd MMM yyyy, h:mm a', {locale: es});
}

export function safeParseTimestamp(date: string): [boolean, Date | null] {
  if (!date) {
    return [false, null];
  }

  try {
    const dateVal = parseJSON(date);
    if(!isNaN(dateVal.getTime())) {
      return [true, dateVal];
    } else {
      return [false, null];
    }
  } catch (e) {
    return [false, null];
  }
}

export function formatDate(date: string): string {
  if (!date) {
    return '';
  }

  return format(parseISO(date), 'd MMM yyyy', {locale: es});
}

export function formatDateISO(date: Date | number | string): string {
  if (typeof (date) === 'string') {
    return formatISO(parseISO(date), {representation: 'date'});
  }
  return formatISO(date, {representation: 'date'});
}

export function formatTimestampRFC3339(date: string): string {
  if (!date) {
    return '';
  }
  const returnVal = formatRFC3339(parseISO(date));
  return returnVal.substring(0, 16);
}

export function formatCurrency(amount: number): string {
  if(typeof(amount) === 'undefined' || amount === null) {
    return '';
  }
  return `$ ${amount.toFixed(2)}`
}

export function parseISODate(dateStr: string): Date | null {
  if(typeof(dateStr) === 'undefined' || dateStr === null || dateStr.length === 0) {
    return null;
  }

  return parseISO(dateStr);
}

export function formatElapsedTime(dateStr: string) {
  if(typeof(dateStr) === 'undefined' || dateStr === null || dateStr.length === 0) {
    return null;
  }

  const parsedDate = parseISO(dateStr);
  return intlFormatDistance(parsedDate, new Date(), {locale: 'es'})
}

export function formatDurationHuman(seconds: number): string {
  if (typeof(seconds) === 'undefined') {
    return '';
  }

  if (seconds === 0) {
    return '0 segundos';
  }

  const absSeconds = Math.abs(seconds);

  const hours = Math.floor(absSeconds / 3600);
  const minutes = Math.floor((absSeconds % 3600) / 60);
  const remainingSecs = absSeconds % 60;
  const format = hours > 0 ? ['hours', 'minutes'] : ['minutes', 'seconds'];
  return (seconds < 0 ? '-' : '') + formatDuration({hours, minutes, seconds: remainingSecs}, {format, locale: es});
}

export function formatDurationHumanShort(seconds: number): string {
  const formattedDurationLong = formatDurationHuman(seconds);
  return formattedDurationLong
    .replace(/segundos?/, 's')
    .replace(/minutos?/, 'min')
    .replace(/horas?/, 'h');
}

const METERS_PER_MILE = 1609.344;
const FEET_PER_METER = 3.28084;

export function formatDistanceHuman(meters: number): string {
  if (typeof(meters) === 'undefined') {
    return '';
  }

  if (meters === 0) {
    return '0 pies';
  }

  let result: string[] = [];
  const miles = Math.floor(meters / METERS_PER_MILE);
  if (miles > 0) {
    result.push(miles + ' milla' + (miles > 1 ? 's' : ''));
  }
  const feet = Math.floor((meters % METERS_PER_MILE) * FEET_PER_METER);
  if (feet > 0) {
    result.push(feet + ' pie' + (feet > 1 ? 's' : ''));
  }
  return result.join(' ');
}

export function formatDistanceHumanShort(meters: number): string {
  if (typeof(meters) === 'undefined') {
    return '';
  }

  if (meters === 0) {
    return '0 ft';
  }

  let result: string[] = [];
  const miles = Math.floor(meters / METERS_PER_MILE);
  if (miles > 0) {
    result.push(miles + ' mi');
  }
  const feet = Math.floor((meters % METERS_PER_MILE) * FEET_PER_METER);
  if (feet > 0) {
    result.push(feet + ' ft');
  }
  return result.join(' ');
}