import {Feature} from "@turf/helpers";
import {MapboxMap} from "react-map-gl";
import {MapMouseEvent, MapTouchEvent} from "mapbox-gl";

type EventHandlerFunction = (event: MapMouseEvent | MapTouchEvent) => void

export const DEFAULT_VIEWPORT = {
  latitude: 18.360,
  longitude: -66.0583,
  zoom: 10,
  bearing: 0,
};


export function createFeatureEventHandler(layerId: string,
                                          featureCallback: (feature: Feature) => void): EventHandlerFunction {

  return function eventHandler(event) {
    const map = event.target as MapboxMap;
    const features = map.queryRenderedFeatures(event.point, {
      layers: [layerId]
    });
    if (!features.length) {
      return;
    }
    featureCallback(features[0] as Feature);
  };
}