import TitleRow from '../../components/TitleRow';
import {FaArrowLeft, FaSave} from 'react-icons/fa';
import AlertForm from './AlertForm';
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useFindAlertQuery, usePostAlertMutation} from "../../redux/services/alert";
import {useGetAllUsersQuery} from "../../redux/services/userAdmin";
import {emptyPage} from "../../utils/types";

export default function AlertEdit() {

  const {id} = useParams();
  const navigate = useNavigate();
  const {data: dbRecord = {}} = useFindAlertQuery(id);
  const [postAlert, postAlertResult] = usePostAlertMutation();
  const {data: users = emptyPage } = useGetAllUsersQuery();

  function save(formPayload) {
    postAlert(formPayload);
  }

  useEffect(() => {
    if (!postAlertResult.isSuccess) {
      return;
    }

    toast.success("El aviso fue modificado exitosamente.");
    navigate(`/alert/${id}`);
    setTimeout(() => window.location.reload(), 1000);
  }, [postAlertResult.isSuccess]);

  return (
    <main className='container'>

      {/*<pre className='small pre-scrollable'>{JSON.stringify(dbRecord, null, 2)}</pre>*/}

      <TitleRow
        title={`Modificar aviso id ${dbRecord?.alert_id}`}
        buttons={
          <button
            type='button' className='btn btn-outline-primary'
            onClick={() => navigate(`/alert/${id}`)}
          ><FaArrowLeft /> Regresar
          </button>
        }
      />
      <AlertForm
        record={dbRecord}
        onSubmit={(payload) => save(payload)}
        users={users.records}
        buttons={
          <>
            <button
              type='button' className='btn btn-outline-primary'
              onClick={() => navigate(`/alert/${id}`)}
            ><FaArrowLeft /> Regresar
            </button>
            <button className='btn btn-primary' type='submit'><FaSave /> Enviar</button>
          </>
        }
      />
    </main>
  );
}