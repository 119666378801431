import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

export const OPS_LOG_ACTIVE_TAB_KEY = 'opsLogActiveTab';

export interface UiState {
  opsLogActiveTab: string | null;
}

const initialState: UiState = {
  opsLogActiveTab: localStorage.getItem(OPS_LOG_ACTIVE_TAB_KEY) || "summary",
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setOpsLogActiveTab: (state, action: PayloadAction<string>) => {
      localStorage.setItem(OPS_LOG_ACTIVE_TAB_KEY, action.payload);
      state.opsLogActiveTab = action.payload;
    },
  },
});

export const {
  setOpsLogActiveTab,
} = uiSlice.actions;

export default uiSlice.reducer;
