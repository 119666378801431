import {api} from './api';
import {AuditFieldsType} from "../../utils/types";
import {FeatureCollection, Point} from "geojson";

type Vehicle = {
  id
  src_id
  vehicle_group_id
  name
  plate
  routes
  gpsprovider
  veh_model_id
}

type VehicleListItem = Vehicle & AuditFieldsType;

type VehiclePositionProperties = {
  vehicle_src_id: string;
  vehicle_name: string;
  subroute_name: string;
  route_code: string;
  route_color: string;
  route_name: string;
  avg_speed: number;
  speed: number;
  bearing: number;
  freshness: number;
  last_position_change_ts: string;
  possible_route_codes: string;
  possible_subroute_codes: string;
}


export const vehicleApi = api.injectEndpoints({
  endpoints: build => ({
    getVehicles: build.query<Vehicle[], void>({
      query: () => '/vehicle',
      providesTags: ['VehicleList'],
    }),
    getVehiclePositions: build.query<FeatureCollection<Point, VehiclePositionProperties>, void>({
      query: () => '/vehicle/positions'
    }),
    getAdminVehiclePositions: build.query<FeatureCollection<Point, VehiclePositionProperties>, void>({
      query: () => '/vehicle/adminPositions'
    }),
    getVehiclePositionsByRouteCode: build.query<FeatureCollection<Point, VehiclePositionProperties>, {routeCode: string}>({
      query: ({routeCode}) => '/vehicle/positionsByRouteCode/' + routeCode
    }),
  }),
});

export const {
  useGetVehiclesQuery,
  useGetVehiclePositionsQuery,
  useGetAdminVehiclePositionsQuery,
  useGetVehiclePositionsByRouteCodeQuery,
} = vehicleApi;
