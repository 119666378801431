import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {store} from './redux/store';
import {Provider} from "react-redux";
import {ToastContainer} from 'react-toastify';
import LazyRouter from "./utils/lazyNavigation";

function App() {
  return (
    <Provider store={store}>
      <LazyRouter/>
      <ToastContainer theme='colored'/>
    </Provider>
  );
}

export default App;
