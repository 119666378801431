import {api} from './api';
import {AuditFieldsType} from "../../utils/types";

type Route = {
  id: number
  code: string;
  name: string;
  gpsenabled: boolean;
  opening_hours: string;
  fare: number;
  color: string;
  operator: number;
  network: boolean;
  mode: string;
  disabled: number;
}

type RouteListItem = Route & AuditFieldsType;


export const routeApi = api.injectEndpoints({
  endpoints: build => ({
    getRoutes: build.query<Route[], void>({
      query: () => '/route/all',
      providesTags: ['RouteList'],
    }),
    getActiveRoutes: build.query<Route[], void>({
      query: () => '/route/active',
      providesTags: ['ActiveRouteList'],
    }),
    getLinksByRouteCodes: build.query<Route[], string[]>({
      query: (routeCodes) => `/route/linksByRouteCodes/${routeCodes.join(",")}`,
    }),
    getLinks: build.query<Route[], void>({
      query: () => '/route/links',
    }),
    findRoute: build.query<{ type: 'FeatureCollection', features: {}[] }, number>({
      query: (log_id) => `/route/${log_id}`,
    }),
    postRoute: build.mutation<number, Route>({
      query: (record) => {
        return {
          url: '/route',
          method: 'POST',
          body: record,
        };
      },
      invalidatesTags: ['RouteList'],
    }),

  }),
});

export const {
  useGetRoutesQuery,
  useGetActiveRoutesQuery,
  useGetLinksQuery,
  useGetLinksByRouteCodesQuery,
} = routeApi;
