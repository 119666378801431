import React, {useEffect, useState} from 'react';
import {Layer, Source, useMap} from 'react-map-gl';
import turfDistance from '@turf/distance';
import turfAlong from '@turf/along';
import turfBearing from '@turf/bearing';
import * as turf from '@turf/helpers';
import {emptyFeatureCollection} from "../utils/types";
import mapboxgl from "mapbox-gl";
import {interpolateFromZoom} from "../utils/utils";
import {formatElapsedTime, formatTimestamp, parseISODate} from "../utils/format";


const BLUE = '#0b46a9';
export default function VehLayer({
                                   nextVehicleData = emptyFeatureCollection,
                                   isFetching,
                                   isSuccess,
                                   showAdmin = false,
                                 }) {


  const ANIMATION_STEPS = 100;
  const [vehicleData, setVehicleData] = useState(emptyFeatureCollection);
  const [animationData, setAnimationData] = useState({});
  const [animationCounter, setAnimationCounter] = useState(0);
  const {current: map} = useMap();
  const [cronHandle, setCronHandle] = useState();

  useEffect(() => {
    map.on('click', 'vehicle-icon-lyr', event => openPopup(event.features[0]));
    map.on('mouseover', 'vehicle-icon-lyr', () => map.getMap().getCanvas().style.cursor = 'pointer');
    map.on('mouseout', 'vehicle-icon-lyr', () => map.getMap().getCanvas().style.cursor = '');
  }, []);

  function updateFreshness() {
    if(vehicleData.features.length === 0) {
      return;
    }
    const nextFeatures = vehicleData.features.map(it => {
      const parsedDeviceTs = parseISODate(it.properties.device_ts);
      let nextFreshness = it.properties.freshness;
      if (parsedDeviceTs) {
        nextFreshness = Math.round((new Date().getTime() - parsedDeviceTs.getTime()) / 1000);
      }
      return ({
        ...it,
        properties: {
          ...it.properties,
          freshness: nextFreshness
        }
      });
    });
    setVehicleData({type: 'FeatureCollection', features: nextFeatures});
  }

  useEffect(() => {
    setCronHandle(setInterval(updateFreshness, 1000))
    return function cleanup() {
      clearInterval(cronHandle);
    }
  }, []);


  useEffect(() => {
    if (animationCounter === 0) {
      animate();
    }
    // Request the next frame of animation so long the end has not been reached.
    if (animationCounter < ANIMATION_STEPS) {
      requestAnimationFrame(animate);
    }
  }, [animationCounter]);

  useEffect(() => {
    if (isFetching || !isSuccess) {
      return;
    }

    if (nextVehicleData.features.length === 0) {
      return;
    }

    setAnimationData(calcAnimationData());
    setAnimationCounter(0);

  }, [isFetching, isSuccess]);

  function calcAnimationData() {

    return nextVehicleData.features.reduce((result, nextVehPos) => {
      let prevVehPos = vehicleData.features.find(f => nextVehPos.id === f.id);
      if (!prevVehPos) {
        result[nextVehPos.id] = {
          next: nextVehPos,
          curr: nextVehPos,
          trail: [],
        };
        return result;
      }

      const vehTrailEndpointCoords = [prevVehPos.geometry.coordinates, nextVehPos.geometry.coordinates];
      const lineDistance = turfDistance(...vehTrailEndpointCoords, {units: 'meters'});
      const segmentedTrail = [];

      for (let i = 0; i < lineDistance; i += lineDistance / ANIMATION_STEPS) {
        const segment = turfAlong({type: 'LineString', coordinates: vehTrailEndpointCoords}, i, {units: 'meters'});
        segmentedTrail.push(segment.geometry.coordinates);
      }

      result[nextVehPos.id] = {
        next: nextVehPos,
        curr: prevVehPos,
        trail: segmentedTrail,
      };

      return result;

    }, {});
  }

  function animate() {

    const nextFeatures = [];
    const counter = animationCounter;

    for (const vehId in animationData) {
      if (!animationData.hasOwnProperty(vehId)) {
        continue;
      }

      const animationDatum = animationData[vehId];
      if (animationDatum.trail.length === 0) {
        nextFeatures.push(animationDatum.curr);
        continue;
      }

      if (counter >= ANIMATION_STEPS - 1) {
        nextFeatures.push(animationDatum.next);
        continue;
      }

      const currFeature = {
        ...animationDatum.curr,
        geometry: {
          ...animationDatum.curr.geometry,
          coordinates: animationDatum.trail[counter],
        }
      };

      // Update point geometry to a new position based on counter denoting
      // the index to access the arc.
      // currFeature.geometry.coordinates = animationDatum.trail[counter];

      // Calculate the bearing to ensure the icon is rotated to match the route arc
      // The bearing is calculate between the current point and the next point, except
      // at the end of the arc use the previous point and the current point

      try {
        let transitionBearing = turfBearing(
          turf.point(animationDatum.trail[counter]),
          turf.point(animationDatum.trail[counter + 1])
        );
        if(transitionBearing < 0) {
          transitionBearing = 360 + transitionBearing;
        }
        currFeature.properties.bearing = transitionBearing;
      } catch (err) {
        console.error(err);
      }
      nextFeatures.push(currFeature);
    }

    setVehicleData({
      type: 'FeatureCollection', features: nextFeatures.map(it => {
        const {bearing} = it.properties;

        let bearingIconRotation = 0;
        const stepSizeDegrees = 22.5;
        for (let i = 0; i < 360; i += stepSizeDegrees) {
          if (Math.abs(bearing - i) < (stepSizeDegrees / 2)) {
            bearingIconRotation = i;
            break;
          }
        }
        const parsedDeviceTs = parseISODate(it.properties.device_ts);
        let nextFreshness = it.properties.freshness;
        if (parsedDeviceTs) {
          nextFreshness = Math.round((new Date().getTime() - parsedDeviceTs.getTime()) / 1000);
        }
        return {
          ...it,
          properties: {
            ...it.properties,
            bearingIconRotation,
            freshness: nextFreshness,
          }
        }
      })
    });
    setAnimationCounter(animationCounter + 1);
  }

  function openPopup(feature) {
    const vehProps = feature.properties;
    let popupTitle = vehProps.subroute_name || vehProps.route_name;
    if (!popupTitle) {
      if (vehProps.possible_route_codes && (vehProps.time_since_move_s || vehProps.freshness) < 3600) {
        popupTitle = `Rutas posibles: ${vehProps.possible_route_codes}`;
      } else {
        popupTitle = 'Fuera de servicio';
      }
    }
    let htmlContent = `
      <div id="vehicle-popup-content-${vehProps.vehicle_src_id}">
        <div style="font-weight: bold">${popupTitle}</div>
        <div>Vehículo: ${vehProps.vehicle_name}</div>
        <div>Actualizado: ${formatElapsedTime(vehProps.device_ts)}</div>
    `;
    if(showAdmin) {
      htmlContent += `
      <div>ID: ${feature.id}</div>
      <div>Movimiento: ${formatElapsedTime(vehProps.last_position_change_ts)}</div>
      <div>Rumbo: ${vehProps.bearing} °</div>
      <div>Velocidad: ${vehProps.speed} mph</div>
      <div>Velocidad promedio: ${vehProps.avg_speed} mph</div>
      `;
    }
    htmlContent += '</div>'
    new mapboxgl.Popup()
      .setLngLat(feature.geometry.coordinates)
      .setHTML(htmlContent)
      .addTo(map.getMap());
  }

  function createOffset(radiusOffset, anchorOffset = [0, 0]) {
    const cases = [];
    const stepCount = 16;
    const stepSizeDegrees = 360 / stepCount;
    for (let i = 0; i < stepCount; i++) {
      cases.push(
        ['==', ['get', 'bearingIconRotation'], i * stepSizeDegrees],
        ['literal', [
          radiusOffset * Math.sin(i * Math.PI / (stepCount / 2)) + anchorOffset[0],
          -radiusOffset * Math.cos(i * Math.PI / (stepCount / 2)) + anchorOffset[1],
        ]]
      );
    }
    return [
      'case',
      ...cases,
      ['literal', [0, -radiusOffset]]
    ];
  }

  return (
    <>

      {/*<pre className='small pre-scrollable'>{JSON.stringify(createTextOffset(5), null,)}</pre>*/}

      <Source
        id="vehicle-src"
        type='geojson'
        data={vehicleData}
      />

      <Layer
        type="circle"
        id='vehicle-shadow-lyr'
        source="vehicle-src"
        paint={{
          "circle-radius": interpolateFromZoom(10),
          "circle-color": '#000',
          "circle-opacity": 0.2,
          "circle-blur": 0.5,
          'circle-translate': [0, 0],
          'circle-pitch-alignment': 'map',
          // 'circle-translate-anchor': 'viewport',
        }}
      />

      <Layer
        id="vehicle-icon-lyr"
        type="symbol"
        source="vehicle-src"
        paint={{
          'icon-translate': [
            'interpolate', ['linear'], ['zoom'],
            10, ['literal', [0, -10]],
            20, ['literal', [0, -25]],
          ],
        }}
        layout={{
          "icon-image": "bus-marker",
          "icon-size": interpolateFromZoom(1),
          "icon-allow-overlap": true,
        }}
      />

      <Layer
        id="vehicle-arrow-lyr"
        type="symbol"
        source="vehicle-src"
        paint={{
          'icon-translate': [
            'interpolate', ['linear'], ['zoom'],
            10, ['literal', [0, -13]],
            20, ['literal', [0, -40]],
          ],
        }}
        layout={{
          "icon-image": "caret-up-solid",
          "icon-size": interpolateFromZoom(1),
          "icon-allow-overlap": true,
          "icon-rotate": ["get", "bearingIconRotation"],
        }}
        minzoom={12}
        filter={[
          'all',
          ["!=", ["get", "bearingIconRotation"], null],
          ['!=', ["length", ["get", "possible_route_codes"]], 0],
          ['<', ['get', 'time_since_move_s'], 3600],
        ]}
      />

      <Layer
        id="vehicle-route-label-lyr"
        type="symbol"
        source="vehicle-src"
        paint={{
          "text-color": BLUE,
          "text-translate": [
            'interpolate', ['linear'], ['zoom'],
            12, ['literal', [0, -9]],
            20, ['literal', [0, -14]],
          ],
        }}
        layout={{
          "text-font": ['DIN Pro Bold'],
          "text-size": interpolateFromZoom(9),
          "text-field": ['coalesce', ['get', 'route_code'], '?'],
          'text-allow-overlap': true,
        }}
        minzoom={10}
        filter={[
          'all',
          ['<', ['get', 'time_since_move_s'], 3600],
          ['!=', ["length", ["get", "possible_route_codes"]], 0]
        ]}
      />

      <Layer
        id="vehicle-freshness-badge-lyr"
        type="circle"
        source="vehicle-src"
        paint={{
          "circle-color": BLUE,
          "circle-radius": interpolateFromZoom(6),
          "circle-translate": [
            'interpolate', ['linear'], ['zoom'],
            10, ['literal', [5, -20]],
            20, ['literal', [15, -75]],
          ],
          'circle-opacity': [
            'case',
            ['>', ['get', 'freshness'], 30], 0.8,
            1
          ]
        }}
        minzoom={12}
        filter={['<', ['get', 'freshness'], 99]}
      />

      <Layer
        id="vehicle-freshness-icon-lyr"
        type="symbol"
        source="vehicle-src"
        paint={{
          "icon-translate": [
            'interpolate', ['linear'], ['zoom'],
            10, ['literal', [5, -20]],
            20, ['literal', [15, -75]],
          ],
          'icon-opacity': [
            'case',
            ['>', ['get', 'freshness'], 30], 0.6,
            1
          ]
        }}
        layout={{
          "icon-image": "wifi-icon",
          "icon-size": interpolateFromZoom(0.4),
          "icon-allow-overlap": true,
          "icon-rotate": 45,
        }}
        minzoom={12}
        filter={['<', ['get', 'freshness'], 99]}
      />
    </>
  );
}

