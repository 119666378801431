import Menu from "../components/Menu";
import {useLazyGetUserProfileQuery} from "../redux/services/userProfile";
import React, {useEffect} from "react";
import {useCheckToken, useLogout} from "../utils/hooks";
import {Outlet, useLocation} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {setUser} from "../redux/userSlice";


export default function SidebarLayoutView() {
  const [getUserProfile, {loading, data: user, isError, error}] = useLazyGetUserProfileQuery();
  const location = useLocation();
  const logout = useLogout();
  const dispatch = useDispatch();
  useCheckToken(() => {
    getUserProfile();
  });

  useEffect(() => {
    if(!loading && user) {
      dispatch(setUser(user));
    }
  }, [loading, user]);

  useEffect(() => {
    if(isError) {
      console.error(error);
    }
  }, [isError])

  function handleLogout() {
    logout();
  }

  if(error) {
    return (
      <div>{error.status} {JSON.stringify(error.data)}</div>
    )
  }

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'nowrap',
      height: '100vh',
      maxHeight: '100vh',
      overflowX: 'auto',
      overflowY: 'hidden',
    }}
    >
      <div className='d-lg-none'>
        <Menu.OffCanvas currentPath={location} user={user} onLogout={handleLogout}/>
      </div>
      <Menu.Sidebar currentPath={location} user={user} onLogout={handleLogout}/>

      <div className='d-flex flex-column flex-grow-1 p-3' style={{overflowY: 'scroll'}}>
        <Outlet/>
      </div>
    </div>
  );
}