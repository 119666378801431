export const onChange = (handler) => (e) => handler(e.target.value);

export function shadeColor(color, percent) {
    var num = parseInt(color.slice(1),16);
    var amt = Math.round(2.55 * percent);
    var R = (num >> 16) + amt;
    var B = (num >> 8 & 0x00FF) + amt;
    var G = (num & 0x0000FF) + amt;
    return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255))
        .toString(16).slice(1);
}

export function interpolateFromZoom(size, factor = 1) {
    return [
        'interpolate', ['linear'], ['zoom'],
        10, ['*', size, 0.5 * factor],
        18, ['*', size, 2 * factor],
    ]
}

export function getDistanceFromLatLonInMeters(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6375; // Radius of the earth in km at 18 deg north
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d * 1000;
}

function deg2rad(deg: number): number {
    return deg * (Math.PI / 180)
}

