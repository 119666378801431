import PageNav from './PageNav';

export default function PaginatedList({
                                        data: { total_records, records = [], page_number, setPage_number, page_size = 10 },
                                        noRecordsText = 'No hay datos.',
                                        renderRow = (it, idx = 0) => {
                                        },
                                      }) {

  return (
    <>
      {total_records === 0 && (
        <div className='alert alert-warning'>
          <div className='row'>
            <div className='col-8'>{noRecordsText}</div>
          </div>
        </div>
      )}

      {records.map((it, idx) => {
        return renderRow(it, idx)
      })}

      {total_records > page_size && (
        <PageNav
          pageNumber={page_number}
          pageSize={page_size}
          totalRecords={total_records}
          setPageNumber={setPage_number}
        />
      )}
    </>
  );

}