import ValidatedForm from '../../components/ValidatedForm';
import FormControl from '../../components/FormControl';
import PaddedRightRow from '../../components/PaddedRightRow';
import {useEffect} from 'react';
import {useInput} from '../../utils/hooks';
import {Col, Row} from "../../components/Primitives";
import FormSelect from "../../components/FormSelect";
import {formatTimestampRFC3339} from "../../utils/format";

export default function AlertForm({
                                    record,
                                    users = [],
                                    onSubmit = (payload = {}) => {
                                    },
                                    buttons = <></>,
                                  }) {

  const fields = {
    alert_id: useInput(''),
    header: useInput(''),
    description: useInput(''),
    start_ts: useInput(''),
    end_ts: useInput(''),
  };

  useEffect(() => {
    if (!record) {
      return;
    }

    for (const [k, v] of Object.entries(fields)) {
      if (typeof (record[k]) !== 'undefined' && record[k] !== null) {
        if (typeof (record[k]) === 'boolean') {
          v.setValue(record[k]);
        } else if (k === 'start_ts' || k === 'end_ts') {
          v.setValue(formatTimestampRFC3339(record[k]))
        } else {
          v.setValue(String(record[k]));
        }
      }
    }

  }, [record]);

  async function handleValidSubmit(event) {
    if (!onSubmit) {
      return;
    }

    event.preventDefault();
    const payload = {};
    for (const k of Object.keys(fields)) {
      payload[k] = fields[k].value;
    }

    try {
      await onSubmit(payload);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ValidatedForm onValidSubmit={handleValidSubmit}>
      {/*<pre className='small pre-scrollable'>{JSON.stringify({start_ts: fields.start_ts.value, end_ts: fields.end_ts.value}, null, 2)}</pre>*/}

      <Row>
        <Col>
          <FormControl
            required
            id='header'
            placeholder='Título del aviso'
            label='Título'
            {...fields.header.bind}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormControl
            type='datetime-local'
            required
            id='start_ts'
            label='Fecha y hora desde'
            {...fields.start_ts.bind}
            onChange={(event) => {
              fields.start_ts.setValue(event.target.value);
            }}
          />
        </Col>
        <Col>
          <FormControl
            type='datetime-local'
            required
            min={fields.start_ts.value}
            cols={3}
            id='description'
            label='Fecha y hora hasta'
            {...fields.end_ts.bind}
            onChange={(event) => {
              fields.end_ts.setValue(event.target.value);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormControl
            type='textarea'
            rows={5}
            required
            placeholder="Redacte un aviso breve para los usuarios de la Línea de San Juan"
            maxLength={5000}
            id='description'
            label='Contenido'
            {...fields.description.bind}
          />
        </Col>
      </Row>

      <PaddedRightRow>
        {buttons}
      </PaddedRightRow>
    </ValidatedForm>
  );
}