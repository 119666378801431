import {api} from './api';
import {AuditFieldsType, Page} from "../../utils/types";

type Alert = {
  alert_id: number
  header: string;
  description: string;
  start_ts: string;
  end_ts: string;
}

type AlertListItem = Alert & {
} & AuditFieldsType;


export const alertApi = api.injectEndpoints({
  endpoints: build => ({
    getAllAlerts: build.query<Page<Alert>, void>({
      query: () => `/alert?page_size=100`,
      providesTags: ['AlertList']
    }),
    getAlerts: build.query<Page<Alert>, { page_number?: number, page_size?: number, filter?: string, }>({
      query: (queryParams) => {
        const urlParams = new URLSearchParams();
        if(typeof(queryParams) === 'object') {
          Object.entries(queryParams).forEach(([k, v]) => urlParams.append(k, String(v)))
        }
        return `/alert?${urlParams.toString()}`;
      },
    }),
    getActiveAlerts: build.query<Page<Alert>, { page_number?: number, page_size?: number, active_ts?: string }>({
      query: (queryParams) => {
        const urlParams = new URLSearchParams();
        if(typeof(queryParams) === 'object') {
          Object.entries(queryParams).forEach(([k, v]) => urlParams.append(k, String(v)))
        }
        return `/alert/active?${urlParams.toString()}`;
      },
    }),
    findAlert: build.query<AlertListItem, number>({
      query: (alert_id) => `/alert/${alert_id}`,
    }),
    postAlert: build.mutation<number, Alert>({
      query: record => ({
        url: '/alert',
        method: 'POST',
        body: record,
      }),
      invalidatesTags: ['AlertList'],
    }),
    deleteAlert: build.mutation<void, number>({
      query: alertId => ({
          url: '/alert/' + alertId,
          method: 'DELETE'
        }),
      invalidatesTags: ['AlertList'],
    })

  }),
});

export const {
  useGetAllAlertsQuery,
  useLazyGetAllAlertsQuery,
  useLazyGetAlertsQuery,
  useLazyGetActiveAlertsQuery,
  useFindAlertQuery,
  useDeleteAlertMutation,
  usePostAlertMutation,
} = alertApi;
