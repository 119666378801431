
function matchesAuthority(user, regex) {

  if (!user || !user.authorities || user.authorities.length < 1) {
    return false;
  }

  //test admin
  if (user.authorities.find(it => regex.test(it))) {
    return true;
  }

}

export default function IfAuthorized({ children, match, user, accessFunc }) {

  if (!user) {
    return null;
  }

  if (accessFunc
    && accessFunc(user)) {

    return (
      children
    );
  }

  if (match && matchesAuthority(user, match)) {
    return (
      children
    );
  }

  return null;

}