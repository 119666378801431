import TitleRow from '../../components/TitleRow';
import {FaArrowLeft, FaSave} from 'react-icons/fa';
import {useNavigate} from "react-router-dom";
import AlertForm from "./AlertForm";
import {usePostAlertMutation} from "../../redux/services/alert";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {emptyPage} from "../../utils/types";
import {useGetAllUsersQuery} from "../../redux/services/userAdmin";

export default function AlertNew() {

  const navigate = useNavigate();
  const [postAlert, {data, isError, isSuccess, error}] = usePostAlertMutation();
  const {data: users = emptyPage } = useGetAllUsersQuery();

  function save(record) {
    postAlert(record);
  }

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    toast.success("El aviso fue agregado exitosamente.");
    navigate(`/alert/${data}`)
  }, [isSuccess]);

  return (
    <main className='container'>
      <TitleRow
        title='Agregar aviso nuevo'
        buttons={
          <button
            type='button' className='btn btn-outline-primary'
            onClick={() => navigate('/alert')}
          ><FaArrowLeft/> Regresar
          </button>
        }
      />

      {/*<pre>{JSON.stringify({startDate, alertId, vehicleId}, null, 2)}</pre>*/}

      <AlertForm
        record={{}}
        users={users.records}
        onSubmit={(record) => save(record)}
        buttons={
          <>
            <button
              type='button' className='btn btn-outline-primary'
              onClick={() => navigate('/alert')}
            ><FaArrowLeft/> Regresar
            </button>
            <button className='btn btn-primary' type='submit'><FaSave/> Enviar</button>
          </>
        }
      />
    </main>
  );
}

