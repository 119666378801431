import React, {useEffect, useState} from 'react';
import {Layer, Source, useMap} from 'react-map-gl';
import {interpolateFromZoom, shadeColor} from "../utils/utils";
import mapboxgl from "mapbox-gl";
import {emptyFeatureCollection} from "../utils/types";

const DEFAULT_BLUE = "#0e3e3d";

export default function StopLayer({activeRoutes, stopData, useDefaultColor = false}) {

  const {current: map} = useMap();

  const [activeStops, setActiveStops] = useState(emptyFeatureCollection);

  useEffect(() => {
    map.on('click', 'stops-lyr', event => openPopup(event.features[0]));
    map.on('mouseover', 'stops-lyr', () => map.getMap().getCanvas().style.cursor = 'pointer');
    map.on('mouseout', 'stops-lyr', () => map.getMap().getCanvas().style.cursor = '');
  }, []);

  useEffect(() => {
    const activeStopFeatures = [];
    stopData.features.forEach(stop => {
      let routeCount = 0;
      let routeColor = DEFAULT_BLUE;
      activeRoutes.forEach(activeRoute => {
        if (stop.properties.route_codes.indexOf(activeRoute.code) === -1) {
          return;
        }

        routeCount++;
        if(!useDefaultColor) {
          if (routeCount === 1) {
            routeColor = activeRoute.color || DEFAULT_BLUE;
          } else {
            routeColor = 'gray';
          }
        }
        activeStopFeatures.push({
          ...stop,
          properties: {
            ...stop.properties,
            routeColor,
          }
        });
      });
      setActiveStops({type: 'FeatureCollection', features: activeStopFeatures});
    });
  }, [activeRoutes, stopData])

  function openPopup(feature) {
    new mapboxgl.Popup()
      .setLngLat(feature.geometry.coordinates)
      .setHTML(`<h6>${feature.properties.name}. Rutas: ${feature.properties.route_codes}</h6>`)
      .addTo(map.getMap());
  }

  return (
    <>
      <Source
        id="stops-src"
        type="geojson"
        data={activeStops}
      />

      <Layer
        type="circle"
        id='stops-halo-lyr'
        source="stops-src"
        paint={{
          "circle-radius": interpolateFromZoom(6),
          "circle-color": 'white',
          "circle-blur": 0.3,
        }}
        minzoom={13}
      />

      <Layer
        type="circle"
        id='stops-lyr'
        source="stops-src"
        paint={{
          "circle-radius": interpolateFromZoom(3),
          "circle-color": 'white',
          "circle-stroke-width": interpolateFromZoom(1.70),
          "circle-stroke-color": ['get', 'routeColor'],
        }}
        minzoom={13}
      />
    </>
  );
}