import { useEffect, useState } from 'react';

export default function PageNav({
                                  pageNumber = 1,
                                  setPageNumber = () => {
                                  },
                                  totalRecords = 0,
                                  pageSize = 10,
                                  maxPages = 20,
                                }) {

  const [pageIndices, setPageIndices] = useState([]);
  const totalPages = Math.ceil(totalRecords / pageSize);
  useEffect(() => {
    if (totalRecords === 0 || pageSize === 0) {
      return;
    }

    const tmpPageIndices = [];
    let initialPage = 0;
    if (pageNumber > maxPages) {
      initialPage = pageNumber - maxPages - 1;
    }
    const lastVisiblePage = initialPage + maxPages + 1;
    for (let i = initialPage; i < Math.min(totalPages, lastVisiblePage); i++) {
      tmpPageIndices.push(i);
    }
    setPageIndices(tmpPageIndices);
  }, [totalRecords, pageSize, pageNumber]);


  return (
    <div className='row mt-4'>
      <div className='col'>
        <nav>
          <ul className='pagination justify-content-center'>
            <li className={`page-item ${pageNumber === 0 ? 'disabled' : ''}`}>
              <a className='page-link' aria-label='First' onClick={() => setPageNumber(1)}>
                <span aria-hidden='true'>«</span>
                <span className='visually-hidden'>Primero</span>
              </a>
            </li>
            <li className={`page-item ${pageNumber === 0 ? 'disabled' : ''}`}>
              <a className='page-link' aria-label='Previous' onClick={() => setPageNumber(pageNumber - 1)}>
                <span aria-hidden='true'>‹</span>
                <span className='visually-hidden'>Previo</span>
              </a>
            </li>
            {pageIndices.map((it, idx) => (
              <li key={'page-indices-' + idx} className={`page-item ${pageNumber === (it + 1) ? 'active' : ''}`}>
                <a className='page-link' onClick={() => setPageNumber(it + 1)}>{it + 1}</a>
              </li>
            ))}

            <li className={`page-item ${pageNumber >= totalPages ? 'disabled' : ''}`}>
              <a className='page-link' aria-label='Next' onClick={() => setPageNumber(pageNumber + 1)}>
                <span aria-hidden='true'>›</span>
                <span className='visually-hidden'>Próximo</span>
              </a>
            </li>
            <li className={`page-item ${pageNumber >= totalPages ? 'disabled' : ''}`}>
              <a className='page-link' aria-label='Last'
                 onClick={() => setPageNumber(totalPages)}>
                <span aria-hidden='true'>»</span>
                <span className='visually-hidden'>Último</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}