import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useGetStopsByRouteCodeQuery} from "../../redux/services/stop";
import {useGetLinksByRouteCodesQuery, useGetRoutesQuery} from "../../redux/services/route";
import {useGetVehiclePositionsByRouteCodeQuery} from "../../redux/services/vehicle";
import MapComponent from "../../components/MapComponent";
import CustomOverlay from "../../components/CustomOverlay";

export default function SingleRouteMap() {

  const mapRef = useRef();
  const {routeCode} = useParams();

  const urlQueryStr = window.location.search; // could be '?foo=bar'
  const urlQueryParams = new URLSearchParams(urlQueryStr);
  const geolocation = Boolean(urlQueryParams.get('geolocation'));


  const [isMapLoaded, setMapLoaded] = useState(false);
  const [route, setRoute] = useState(null);
  const stops = useGetStopsByRouteCodeQuery(routeCode);
  const routes = useGetRoutesQuery();
  const routeLinks = useGetLinksByRouteCodesQuery([routeCode]);

  const vehiclePositions = useGetVehiclePositionsByRouteCodeQuery({routeCode}, {
    pollingInterval: 3000,
  });

  useEffect(() => {

    /*const client = new Client({
      brokerURL: 'ws://localhost:8080/server',
      onConnect: () => {
        client.subscribe('/topic/vehPos', message => {
          console.log(`Received: ${message.body}`)
        });
      },
    });

    client.activate();*/
  }, []);


  useEffect(() => {
    if (!routes.isSuccess) {
      return;
    }

    const route = routes.data.find(it => it.code === routeCode);
    setRoute(route);

  }, [routes.isSuccess]);

  function handleMapLoad() {
    setMapLoaded(true);
  }

  useEffect(() => {
    if (!routeLinks.isSuccess || !isMapLoaded) {
      return;
    }

    if(geolocation) {
      mapRef.current?.triggerGeolocate();
    } else {
      mapRef.current?.flyToRouteBounds();
    }
  }, [isMapLoaded, routeLinks.isSuccess]);

  return (
    <div style={{height: '100vh'}}>
    <MapComponent
      ref={mapRef}
      stops={stops}
      allRoutes={routes}
      routeLinks={routeLinks}
      vehiclePositions={vehiclePositions}
      activeRoutes={route ? [route] : []}
      showLineaLogo={true}
      showRouteLegend={false}
      onMapLoaded={handleMapLoad}
    >

      <CustomOverlay position='top-left'>
        <div className='p-2'>
          <h2
            className='d-flex align-items-center justify-content-center p-2 text-light'
            style={{
              borderRadius: '6px',
              background: 'linear-gradient(1.07deg, rgba(14,62,61,0.89) 27.81%, rgba(14,62,61,0.73) 98.95%)',
              backdropFilter: 'blur(4px)',
            }}
          >
            {route?.name}
          </h2>
        </div>
      </CustomOverlay>

    </MapComponent>
    </div>
  );

}


