
export default function TitleRow({title, buttons}) {
  return (
    <div className='d-flex mt-3 mb-4 align-items-center justify-content-between'>
      <h3 style={{display: 'inline-block'}}>{title}</h3>
      <div className='d-inline-flex gap-2' style={{columnGap: 5}}>
          {buttons}
      </div>
    </div>
  )
}