import React, {forwardRef, useState} from 'react';

function ValidatedForm({ children, className, onValidSubmit, onReset, ...props }, ref) {

  const [validated, setValidated] = useState(false);

  function validate(form) {
    const formLength = form.length;

    if (form.checkValidity() === false) {
      for (let i = 0; i < formLength; i++) {
        const elem = form[i];
        if ('button' === elem.nodeName.toLowerCase()) {
          continue;
        }


        const errorLabel = elem.closest('.form-group')?.querySelector('.invalid-feedback');
        if (!errorLabel) {
          continue;
        }

        if (!elem.validity.valid) {
          errorLabel.textContent = elem.validationMessage;
        } else {
          errorLabel.textContent = '';
        }

      }

      return false;
    } else {
      for (let i = 0; i < formLength; i++) {
        const elem = form[i];
        if ('button' === elem.nodeName.toLowerCase()) {
          continue;
        }

        const errorLabel = elem.closest('.form-group')?.querySelector('.invalid-feedback');
        if (!errorLabel) {
          continue;
        }

        errorLabel.textContent = '';
      }

      return true;
    }
  }

  function handleSubmit(event) {
    const formEl = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (validate(formEl)) {
      const submitButtons = formEl.querySelectorAll('[type=\'submit\']');
      submitButtons.forEach(elem => elem.disabled = true);

      const submitPromise = onValidSubmit(event);
      if (submitPromise) {
        submitPromise.then(() => {
          submitButtons.forEach(elem => elem.disabled = false);
        }, () => {
          submitButtons.forEach(elem => elem.disabled = false);
        });
      } else {
        submitButtons.forEach(elem => elem.disabled = false);
      }
    }

    setValidated(true);
  }

  function handleReset(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (onReset) {
      onReset(form);
    }
    form.reset();
    setValidated(false);
  }

  let classNames = [];
  if (className) {
    classNames.push(className);
  }
  if (validated) {
    classNames.push('was-validated');
  }

  return (
    <form ref={ref} onSubmit={handleSubmit} onReset={handleReset} {...props} className={classNames} noValidate={true}>
      {children}
    </form>
  );

}

export default forwardRef(ValidatedForm);