import React, {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import Map, {
  GeolocateControl,
  GeolocateControlRef,
  GeolocateResultEvent,
  MapRef,
  NavigationControl,
  ScaleControl,
  ViewStateChangeEvent
} from 'react-map-gl';
import RouteLayer from "./RouteLayer";
import StopLayer from "./StopLayer";
import {Dimensions, emptyFeatureCollection} from "../utils/types";
import VehLayer from "./VehLayer";
import RouteLegend from "./RouteLegend";
import CustomOverlay from "./CustomOverlay";
import LineaLogoBox from "./LineaLogoBox";
import {DEFAULT_VIEWPORT} from "../utils/mapUtils";
import {FlyToOptions} from "mapbox-gl";
import bbox from "@turf/bbox";
import {fitBounds} from "@math.gl/web-mercator";
import MapCrosshairs from "./MapCrosshairs";

type Props = {
  showLineaLogo?: boolean;
  stops: any;
  allRoutes: any;
  routeLinks: any;
  vehiclePositions: any;
  activeRoutes: any;
  showCrossHairs?: boolean;
  showVehAdminData?: boolean;
  showRouteLegend?: boolean;
  onMapLoaded?(): void;
  onToggleRoute?(): void;
  onMoveEnd?(e: ViewStateChangeEvent): void;
  onGeolocate?(e: GeolocateResultEvent): void;
};

interface MapComponentRef {
  flyTo(options: FlyToOptions): void;
  flyToRouteBounds(): void;
  triggerGeolocate(): void;
  getCenter(): {lng: number, lat: number;};
  getContainer(): HTMLElement;
}


const MapComponent = forwardRef(function MapComponent({
                                                        showLineaLogo = false,
                                                        showCrossHairs = false,
                                                        showRouteLegend = true,
                                                        showVehAdminData = false,
                                                        onMapLoaded = () => {
                                                        },
                                                        routeLinks,
                                                        allRoutes,
                                                        vehiclePositions,
                                                        activeRoutes,
                                                        stops,
                                                        onToggleRoute = () => {
                                                        },
                                                        onMoveEnd = () => {
                                                        },
                                                        onGeolocate = () => {
                                                        },
                                                        children
                                                      }: PropsWithChildren<Props>, ref: ForwardedRef<MapComponentRef>) {

  const mapRef = useRef<MapRef>(null);
  const geoLocateControl = useRef<GeolocateControlRef>(null);
  const [mapDimensions, setMapDimensions] = useState<Dimensions | null>(null);

  useImperativeHandle(ref, () => ({
    flyTo: (options: FlyToOptions) => {
      mapRef.current?.flyTo(options)
    },
    flyToRouteBounds: () => {
      // @ts-ignore
      const {clientWidth: width, clientHeight: height} = mapRef.current?.getContainer();
      const [minX, minY, maxX, maxY] = bbox(routeLinks.data);
      const {latitude, longitude, zoom} = fitBounds({
        bounds: [[minX, minY], [maxX, maxY]],
        padding: Math.min(width, height) * 0.05,
        width: width,
        height: height,
      });
      mapRef.current?.flyTo({center: [longitude, latitude], zoom});
    },
    triggerGeolocate: () => {
      geoLocateControl.current?.trigger();
    },
    getCenter: () => {
      return mapRef.current?.getCenter();
    },
    getContainer: () => {
      return mapRef.current?.getContainer();
    },
  } as MapComponentRef), [routeLinks]);

  function handleMapLoad() {
    //@ts-ignore
    const {clientWidth: width, clientHeight: height} = mapRef.current?.getContainer();

    setMapDimensions({width, height})
    onMapLoaded();
  }

  useEffect(() => {
    function handleResizeWindow() {
      //@ts-ignore
      const {clientWidth: width, clientHeight: height} = mapRef.current?.getContainer();
      setMapDimensions({width, height})
    }
    window.addEventListener('resize', handleResizeWindow)
    return () => window.removeEventListener('resize', handleResizeWindow)
  }, []);

  return (
    <>
      <Map
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={DEFAULT_VIEWPORT}
        mapStyle={process.env.REACT_APP_MAPBOX_BASEMAP}
        attributionControl={true}
        onLoad={handleMapLoad}
        dragRotate={false}
        pitchWithRotate={false}
        onMoveEnd={onMoveEnd}
      >

        <RouteLayer
          activeRoutes={activeRoutes}
          routeLinks={routeLinks?.data || emptyFeatureCollection}
        />
        <StopLayer
          activeRoutes={activeRoutes}
          stopData={stops.data || emptyFeatureCollection}/>
        <VehLayer
          showAdmin={showVehAdminData}
          nextVehicleData={vehiclePositions.data}
          isFetching={vehiclePositions.isFetching}
          isSuccess={vehiclePositions.isSuccess}
        />

        {activeRoutes && showRouteLegend && (
          <CustomOverlay position='top-left'>
            <RouteLegend
              activeRoutes={activeRoutes || []}
              routes={allRoutes?.data || []}
              onToggleRoute={onToggleRoute}/>
          </CustomOverlay>
        )}

        {showLineaLogo && (
          <CustomOverlay position='top-right'>
            <LineaLogoBox/>
          </CustomOverlay>
        )}

        <NavigationControl
          position="bottom-left"
          showCompass={false}
          visualizePitch={false}
        />
        <ScaleControl position='bottom-right' unit="imperial"/>
        <GeolocateControl
          ref={geoLocateControl}
          position='bottom-left'
          trackUserLocation={true}
          showUserHeading={true}
          onGeolocate={onGeolocate}
        />

        {showCrossHairs && mapDimensions && (
          <MapCrosshairs mapDimensions={mapDimensions}/>
        )}

        {children}

      </Map>
    </>
  );

})

export default MapComponent;



