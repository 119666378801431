import {api} from './api';
import {AuditFieldsType, Page} from "../../utils/types";

export type UserMutationRequest = {
  user_id: number;
  username: string;
  display_name: string;
  phone?: string;
  email?: string;
  is_disabled: boolean;
  role?: string;
};

export type UserQueryResponse = UserMutationRequest & AuditFieldsType;

export const userAdminApi = api.injectEndpoints({
  endpoints: build => ({
    getAllUsers: build.query<Page<UserQueryResponse>, void>({
      query: () => `/userAdmin?page_size=100`,
    }),
    getUsers: build.query<Page<UserQueryResponse>, {page_number?: number, name?: string}>({
      query: (queryParams) => {
        const urlParams = new URLSearchParams();
        if (typeof (queryParams) === 'object') {
          Object.entries(queryParams).forEach(([k, v]) => urlParams.append(k, String(v)))
        }
        return `/userAdmin?${urlParams.toString()}`;
      },
    }),
    findUser: build.query<UserQueryResponse, number>({
      query: (userId) => `/userAdmin/${userId}`,
    }),
    existsUser: build.query<UserQueryResponse, string>({
      query: (username) => `/userAdmin/exists?username=${username}`,
    }),
    updateUser: build.mutation<void, Partial<UserMutationRequest>>({
      query(data) {
        return {
          url: '/userAdmin',
          method: 'POST',
          body: data,
        };
      },
    }),
    updatePassword: build.mutation<void, Partial<{ user_id: number, password: string }>>({
      query(data) {
        return {
          url: '/userAdmin/password',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyExistsUserQuery,
  useFindUserQuery,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
} = userAdminApi;
