import React, {useEffect, useRef, useState} from 'react';
import {useLazyGetStopsByRouteCodesQuery} from "../../redux/services/stop";
import {useGetActiveRoutesQuery, useGetLinksQuery} from "../../redux/services/route";
import {useGetVehiclePositionsQuery} from "../../redux/services/vehicle";
import MapComponent from "../../components/MapComponent";

export default function PublicMap() {

  const mapRef = useRef();

  const [isMapLoaded, setMapLoaded] = useState(false);
  const [activeRoutes, setActiveRoutes] = useState([]);
  const [getStopsByRouteCodes, stops] = useLazyGetStopsByRouteCodesQuery();
  const allRoutes = useGetActiveRoutesQuery();
  const routeLinks = useGetLinksQuery();

  const vehiclePositions = useGetVehiclePositionsQuery(null, {
    pollingInterval: 3000,
  });

  useEffect(() => {
    if (!allRoutes.isSuccess) {
      return;
    }

    setActiveRoutes(allRoutes.data);
    getStopsByRouteCodes(allRoutes.data.map(it => it.code).join(','));

  }, [allRoutes.isSuccess]);

  function handleMapLoad() {
    setMapLoaded(true);
  }

  useEffect(() => {
    if (!routeLinks.isSuccess || !isMapLoaded) {
      return;
    }
    mapRef.current?.flyToRouteBounds();
  }, [isMapLoaded, routeLinks.isSuccess]);

  function handleToggleRoute(routeCode) {
    const nextActiveRoutes = [...activeRoutes];
    const currIndex = nextActiveRoutes.findIndex(it => it.code === routeCode);
    if(currIndex > -1) {
      nextActiveRoutes.splice(currIndex, 1);
    } else {
      nextActiveRoutes.push(allRoutes.data.find(it => it.code === routeCode));
    }
    setActiveRoutes(nextActiveRoutes);
  }

  return (
    <main>
      {/*<pre className='text-sm'>{JSON.stringify(routeLinks?.data, null, 2)}</pre>*/}
      {/*<pre className='text-sm'>{JSON.stringify(activeRoutes, null, 2)}</pre>*/}
      <div style={{height: '100vh'}}>
        <MapComponent
          ref={mapRef}
          showLineaLogo={true}
          stops={stops}
          allRoutes={allRoutes}
          routeLinks={routeLinks}
          vehiclePositions={vehiclePositions}
          activeRoutes={activeRoutes}
          onMapLoaded={handleMapLoad}
          onToggleRoute={handleToggleRoute} />
      </div>
    </main>
  );

}



