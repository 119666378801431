import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState, cloneElement} from 'react';
import layersIcon from "../images/layers.png";
import {useControl} from "react-map-gl";
import {IControl} from "react-map-gl";
import {createPortal} from 'react-dom';
import {shadeColor} from "../utils/utils";

const DEFAULT_BLUE_DARKER = "#0b46a9";


const mql = window.matchMedia(`(min-width: 600px)`);

export default function RouteLegend({routes, activeRoutes, onToggleRoute}) {

  const [open, setOpen] = useState(mql.matches);

  useEffect(() => {
    mql.addEventListener('change', mediaQueryChanged);
  }, []);

  function mediaQueryChanged() {
    setOpen(mql.matches)
  }

  let legendContent;
  if (open) {
    legendContent = (
      <div>
        {routes.map((route, idx) => {
          return (
            <div key={`route-${idx}`} style={{textAlign: 'left', whiteSpace: 'nowrap'}}>
              <label>
                <input
                  type="checkbox"
                  checked={Boolean(activeRoutes.find(it => it.code === route.code))}
                  onChange={event => onToggleRoute(route.code, event.target.checked)}
                />
                &nbsp;
                <span style={{
                  display: "inline-block",
                  backgroundColor: route.color,
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                }}></span>
                &nbsp;
                <span className="routeLabel" style={{
                }}>{route.name}</span>
              </label>
            </div>
          )
        })}
      </div>
    );
  } else {
    legendContent = <img src={layersIcon} alt="Layers icon"/>;
  }

  return (
    <div
      id='route-legend'
      className='mapboxgl-ctrl mapboxgl-ctrl-legend'
      style={{
        backgroundColor: 'rgb(240, 240, 240, 0.8)',
        padding: 5,
        borderRadius: 5,
        boxShadow: '2px 2px 2px gray',
      }}
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(mql.matches)}
    >
      {legendContent}
    </div>
  );
};