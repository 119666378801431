import React, {useEffect, useState} from 'react';
import {Layer, Source, useMap} from 'react-map-gl';
import {interpolateFromZoom} from "../utils/utils";
import mapboxgl from "mapbox-gl";
import {emptyFeatureCollection} from "../utils/types";


const DEFAULT_BLUE = "#0e3e3d";
export default function RouteLayer({activeRoutes, routeLinks, useDefaultColor = false}) {

  const {current: map} = useMap();
  const [activeLinks, setActiveLinks] = useState(emptyFeatureCollection);

  useEffect(() => {
    map.on('click', 'route-links-lyr', event => openPopup(event.lngLat, event.features[0]));
    map.on('mouseover', 'route-links-lyr', () => map.getMap().getCanvas().style.cursor = 'pointer');
    map.on('mouseout', 'route-links-lyr', () => map.getMap().getCanvas().style.cursor = '');
  }, []);

  useEffect(() => {
    console.debug({activeRoutes: activeRoutes.length, routeLinks: routeLinks.features.length})
    const activeLinkFeatures = [];
    routeLinks.features.forEach(link => {
      let linkHasLeft = false;
      let linkHasRight = false;
      let linkDirection = 0;
      let routeColor = DEFAULT_BLUE;
      let routeCount = 0;
      activeRoutes.forEach(activeRoute => {

        if (!(activeRoute.code in link.properties)) {
          return;
        }

        routeCount++;
        if(!useDefaultColor) {
          if(routeCount === 1) {
            routeColor = activeRoute.color || DEFAULT_BLUE;
          } else {
            routeColor = 'gray';
          }
        }

        linkHasLeft = linkHasLeft || link.properties[activeRoute.code] === -1;
        linkHasRight = linkHasRight || link.properties[activeRoute.code] === 1;
      });

      if (routeCount > 0) {

        if (linkHasLeft !== linkHasRight) {
          linkDirection = linkHasLeft ? -1 : 1;
        }

        activeLinkFeatures.push({
          ...link,
          properties: {
            ...link.properties,
            direction: linkDirection,
            routeColor,
          }
        });

      }
    });
    setActiveLinks({type: "FeatureCollection", features: activeLinkFeatures});
  }, [activeRoutes, routeLinks]);

  function openPopup(lngLat, feature) {
    const routeArray = Object.keys(feature.properties);
    ['routeCount', 'routeColor', 'direction'].forEach(excludedKey => {
      const excludedKeyIdx = routeArray.indexOf(excludedKey);
      if (excludedKeyIdx > -1) {
        routeArray.splice(excludedKeyIdx, 1);
      }
    });

    new mapboxgl.Popup()
      .setLngLat(lngLat)
      .setHTML(`<h6>Rutas: ${routeArray.join(', ')}</h6>`)
      .addTo(map.getMap());
  }

  return (
    <>
      <Source
        id='route-links-src'
        type='geojson'
        data={activeLinks}
      />


      <Layer
        id='route-links-lyr'
        type="line"
        source="route-links-src"
        paint={{
          "line-width": interpolateFromZoom(5),
          "line-color": ['get', 'routeColor'],
          "line-opacity": [
            'case',
            ["==", ["get", 'direction'], 0], 0.95,
            0.75
          ],
        }}
        layout={{
          "line-cap": "round",
        }}
      />

      <Layer
        id={'route-links-decoration'}
        type="symbol"
        source="route-links-src"
        layout={{
          "text-size": interpolateFromZoom(12),
          "symbol-spacing": interpolateFromZoom(20, -1),
          "text-font": ['Open Sans Bold'],
          "text-field": [
            'case',
            ["<", ["get", 'direction'], 0], '«',
            '»'
          ],
          "text-offset": [0, -0.1],
          "symbol-placement": "line",
          "text-keep-upright": false,
        }}
        paint={{
          "text-color": 'white',
          "text-opacity": 0.8,
        }}
        filter={["!=", ["get", 'direction'], 0]}
      />

    </>
  );

}
