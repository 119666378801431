import React from "react";
import {Dimensions} from "../utils/types";

type Props = {
  mapDimensions: Dimensions;
}

export default function MapCrosshairs({mapDimensions}: Props) {
  const crossHairsWidth = 24;
  return (
    <>
      <span style={{
        margin: 'auto',
        position: 'relative',
        top: (mapDimensions.height * 0.5) - crossHairsWidth * 0.5,
        left: (mapDimensions.width * 0.5) - crossHairsWidth * 0.5,
      }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="crosshairs"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10"/>
          <line x1="22" y1="12" x2="18" y2="12"/>
          <line x1="6" y1="12" x2="2" y2="12"/>
          <line x1="12" y1="6" x2="12" y2="2"/>
          <line x1="12" y1="22" x2="12" y2="18"/>
        </svg>
      </span>
    </>

  )
}