export default function FormSelect({
                                      id = '',
                                      name = '',
                                      label = '',
                                      required = false,
                                      className = '',
                                      disabled= false,
                                      options = [],
                                      value = '',
                                      onChange = (event) => {
                                      },
                                      placeholder = '',
                                      hidePlaceholder = false,
                                    }) {
  const failsafeName = name || id;
  const failsafeId = id || name;

  return (
    <div className='form-group mb-3'>
      <label htmlFor={failsafeId}>
        {label} {required ? <span className='text-danger'> *</span> : null}
      </label>
      <select
        id={failsafeId}
        name={failsafeName}
        className='form-select'
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        {!hidePlaceholder && <option value=''>{placeholder || '-- Escoje --'}</option>}
        {options.map((it, idx) => (
          <option key={failsafeId + '-option-' + idx} value={it.id}>{it.description}</option>
        ))}
      </select>
      <div className='invalid-feedback'>&nbsp;</div>
    </div>

  );
}
