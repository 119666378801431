import {api} from './api';
import {Page} from "../../utils/types";


export const observationApi = api.injectEndpoints({
  endpoints: build => ({
    getObservationsByOpsLogId: build.query<Page<object>, {log_id: number, page_number: number}>({
      query: ({log_id, page_number = 1}) => `/imageObservation?log_id=${log_id}&page_number=${page_number}`,
    }),
    findObservation: build.query<object, number>({
      query: (imgObservationId) => `/imageObservation/${imgObservationId}`,
    }),
    postObservation: build.mutation<number, object>({
      query: record => ({
        url: '/imageObservation',
        method: 'POST',
        body: record,
      }),
    }),
    deleteObservation: build.mutation<void, number>({
      query: passengerCountId => ({
        url: `/imageObservation/${passengerCountId}`,
        method: 'DELETE',
      }),
    }),

  }),
});

export const {
  useGetObservationsByOpsLogIdQuery,
  useFindObservationQuery,
  useLazyFindObservationQuery,
  usePostObservationMutation,
  useDeleteObservationMutation,
} = observationApi;
