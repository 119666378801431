import TitleRow from "../../components/TitleRow";
import {useLazyGetActiveAlertsQuery, useLazyGetAlertsQuery} from "../../redux/services/alert";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useInput, usePaging} from "../../utils/hooks";
import {emptyPage} from "../../utils/types";
import IfAuthorized from "../../components/IfAuthorized";
import {useSelector} from "react-redux";
import {DebounceInput} from "react-debounce-input";
import FormControl from "../../components/FormControl";
import {FaGlasses, FaPlus} from "react-icons/fa";
import PaginatedList from "../../components/PaginatedList";
import DisplayField from "../../components/DisplayField";
import {formatTimestamp} from "../../utils/format";
import PaddedRightRow from "../../components/PaddedRightRow";

export default function AlertList() {

  const alerts = usePaging();
  const navigate = useNavigate();
  const {user} = useSelector(state => state.user);
  const searchField = useInput();

  const [getAlerts, {
    data = emptyPage,
    isLoading,
    isSuccess,
    isFetching,
  }] = useLazyGetAlertsQuery();

  useEffect(() => {
    getAlerts();
  }, []);

  useEffect(() => {
    getAlerts({page_number: alerts.page_number, filter: searchField.value});
  }, [alerts.page_number]);

  useEffect(() => {
    getAlerts({page_number: 1, filter: searchField.value});
  }, [searchField.value]);


  useEffect(() => {
    if (!isSuccess || isFetching) {
      return;
    }
    alerts.setData(data)
  }, [isSuccess, isFetching]);

  if (isLoading) {
    return (<p className='container'>Cargando...</p>);
  }

  return (
    <main className='container'>
      <TitleRow
        title={`Avisos (${alerts.total_records})`}
        buttons={(
          <IfAuthorized user={user} match={/ADMIN/}>
            <button
              type='button' className='btn btn-primary'
              onClick={() => navigate(`/alert/new`)}
            ><FaPlus/> Agregar aviso
            </button>
          </IfAuthorized>
        )}
      />

      <DebounceInput
        element={FormControl}
        col={3}
        label='Filtrar por nombre'
        id='searchField'
        type='search'
        placeholder='Introduzca nombre'
        debounceTimeout={300}
        {...searchField.bind}
      />

      {/*<pre className='small pre-scrollable'>{JSON.stringify(alerts.records[0], null, 2)}</pre>*/}

      <div>{alerts.total_records} resultados</div>

      <PaginatedList
        data={alerts}
        noRecordsText={'No hay avisos'}
        renderRow={(it, idx) => (
          <div key={`alert-${idx}`} className='card mb-2'>
            <div className='card-body'>
              <div className='row'>
                <DisplayField label='Título' value={it.header}/>
                <div className="col-6"></div>
              </div>
              <div className='row'>
                <DisplayField label='Desde' value={formatTimestamp(it.start_ts)}/>
                <DisplayField label='Hasta' value={formatTimestamp(it.end_ts)}/>
              </div>
              <div className='row'>
                <div className='col-3'>Contenido</div>
                <div className='col fw-bold'>{it.description}</div>
              </div>

              <PaddedRightRow>
                <button className='btn btn-primary' onClick={() => navigate(`/alert/${it.alert_id}`)}>
                  <FaGlasses/> Ver detalles
                </button>
              </PaddedRightRow>
            </div>
          </div>
        )}
      />
    </main>
  )
}