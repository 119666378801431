import {api} from './api';
import {AuditFieldsType} from "../../utils/types";

type Stop = {
  id: number
  code: string;
  name: string;
  gpsenabled: boolean;
  opening_hours: string;
  fare: number;
  color: string;
  operator: number;
  network: boolean;
  mode: string;
  disabled: number;
}

type StopListItem = Stop & AuditFieldsType;


export const stopApi = api.injectEndpoints({
  endpoints: build => ({
    getStopsByRouteCode: build.query<Stop[], string>({
      query: (routeCode) => `/stop/byRouteCode/${routeCode}`,
    }),
    getStopsByRouteCodes: build.query<Stop[], string>({
      query: (routeCode) => `/stop/byRouteCodes/${routeCode}`,
    }),
    getStopsByRouteId: build.query<Stop[], string>({
      query: (routeId) => `/stop/route/${routeId}`,
    }),
    findStop: build.query<StopListItem, number>({
      query: (stopId) => `/stop/${stopId}`,
    }),
  }),
});

export const {
  useGetStopsByRouteCodeQuery,
  useLazyGetStopsByRouteCodesQuery,
  useLazyGetStopsByRouteIdQuery,
} = stopApi;
