import {api} from './api';

export type UserProfileMutationRequest = {
  display_name: string;
  phone: string;
};

export type UserProfileQueryResponse = {
  user_id: number,
  username: string,
} & UserProfileMutationRequest;

export const userProfileApi = api.injectEndpoints({
  endpoints: build => ({
    getUserProfile: build.query<UserProfileQueryResponse, void>({
      query: () => '/userProfile',
      providesTags: ['User'],
    }),
    updateUserProfile: build.mutation<void, Partial<UserProfileMutationRequest>>({
      query(data) {
        return {
          url: '/userProfile',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
} = userProfileApi;
